import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import axios from 'axios'
import { Label, Select, Button, Spinner } from '../../../kado/components';
import AssActions from '../../../actions/assessments';
import { i18Get } from '../../../i18';
import { isMobile, apiHeaders } from '../../../kado/utils';

import Config from '../../../cankado-config';
import TranslationAtions from '../../../actions/translation';
import Language from '../../../kado/constant-files/language-options';

import './navbar.css';
import '../../../kado/css';

function getLanguages() {
    return  _.sortBy(Language.options, "label").map(x => ({
        ...x,
        label: (
            <Label> 
                
                <img src={`${Config.publicUrl}/locales/${x.value}/icon.png`} />
                <span className="language-text">{x.label} </ span>
            </Label>
        ),
    }));
}

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            fullscreen: window.screen.height === window.innerHeight
        };
        this.selectLanguage = this.selectLanguage.bind(this);
    }
    setOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(true));
    }
    ressetOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(false));
    }
    printPage() {
        this.setState({
            waiting: true,
        });
        axios.post(`${Config.apiRoot}/assessment_pdf/pdf/${this.props.assessments.patient}/`,
            {
                assessment: _.find(this.props.assessments.assList, 'selected').uuid,
                language: this.props.translations.language,
            },
            { ...apiHeaders() }
        ).then(
            (response) => {
                window.open(response.data.url, '_blank')
                this.setState({
                    waiting: false,
                })
            }
        )
    }
    fullScreen(elem) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
            this.setState({
                fullscreen: true
            })
        } else {
            this.setState({
                fullscreen: false
            })
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
    selectLanguage(lang) {
        if (lang) {
            this.props.dispatch(TranslationAtions.ResetLanguage(lang.value));
        }
    }
    renderViewToogler(){
        return(
            <React.Fragment>
                <Button
                    className={this.props.assessments.ui.overview ? 'btn-active' : 'btn-inactive'}
                    onClick={this.setOverview}
                >
                    {i18Get('Overview')}
                </Button>
                <Button
                    className={!this.props.assessments.ui.overview ? 'btn-active' : 'btn-inactive'}
                    onClick={this.ressetOverview}
                >
                    {i18Get('Single question')}
                </Button>
            </React.Fragment>
        )
    }
    render() {
        var elem = document.getElementById("root");
        return (
            <div className="navbar d-flex animated slideInDown py-2">
                <div className="noPadding navbar-title d-flex align-items-center">
                    <img src="/images/logo.png" />
                    {/* {this.props.location.pathname === '/' ? '' : <img src="/images/cankado.png" />} */}
                    <label className="main-heading m-0 ml-1">{i18Get('Assessment')}</label>

                </div>
                <ToastContainer hideProgressBar autoClose={2000} toastClassName="toast_container" className="cankado_toast" />
                
                <div className="d-flex nav-actions">
                    <div className="languageSwitcher">
                        <Select value={this.props.translations.language} backspaceRemoves={false} deleteRemoves={false} escapeClearsValue={false} searchable={false} clearable={false} onChange={this.selectLanguage} options={getLanguages()} />
                    </div>
                    {!isMobile && <div className="print-pdf-div ml-2 mr-2">
                        <button disabled={this.state.waiting} className="d-print-none print-btn" onClick={this.printPage.bind(this)}>
                            {this.state.waiting ? <Spinner /> : <i className="fa fa-print" />}
                        </button>
                    </div>}
                    {!navigator.userAgent.match(/iP(hone|od|ad)/) &&
                        <button title={i18Get('Fullscreen')} onClick={this.fullScreen.bind(this, elem)} className="d-print-none print-btn fullscreen-btn">
                            <i className={!this.state.fullscreen ? "fa fa-expand" : "fa fa-compress"} aria-hidden="true"></i>
                        </button>
                    }

                    <div className="d-none d-sm-flex btn-wrap group-btn ml-2">
                        {this.renderViewToogler()}
                    </div>
                </div>
                <div className="d-sm-none btn-wrap group-btn pt-1 pb-2 w-100">
                    {this.renderViewToogler()}
                </div>
            </div>
        );
    }
}
export default withRouter(connect(store => ({
    user: store.user,
    translations: store.translations,
    assessments: store.assessments,
}))(Navbar));
